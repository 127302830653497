import { ENV, TENANT } from "config/api";
import { TENANT_MAPPING } from "config/constants";

let authConfig = {};

if (ENV === "devs" || ENV === "test" || TENANT.includes("localhost")) {
  //Dev and TEST environments are included here
  if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.VS) {
    authConfig = {
      apiKey: "AIzaSyAHKSk52_L_cMbAT0p31Cck6ed_7CsD8hw",
      authDomain: "victorias-secret-393308.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (
    [
      TENANT_MAPPING.SIGNET,
      TENANT_MAPPING.VB,
      TENANT_MAPPING.PARTY_CITY,
      TENANT_MAPPING.PUMA,
    ].includes(TENANT.toLocaleLowerCase())
  ) {
    authConfig = {
      apiKey: "AIzaSyDAZfJzJ57rMOyAgmLCgSc3L1NkpnKdFVY",
      authDomain: "impactsmart.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (
    [TENANT_MAPPING.PRODUCTIVITY_HELPER, TENANT_MAPPING.GENERIC,TENANT_MAPPING.MTP_INTERNAL].includes(
      TENANT.toLocaleLowerCase()
    )
  ) {
    authConfig = {
      apiKey: "AIzaSyCLzIDuxkqWZV2BvqUKSQU2MP9pdHkd06c",
      authDomain: "platform-internal.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.ARHAUS) {
    authConfig = {
      apiKey: "AIzaSyBcVTV1dvlmWyTeP1QlCMCw73Q_EuLnjy8",
      authDomain: "arhaus-401512.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.BRISCOES) {
    authConfig = {
      apiKey: "AIzaSyBKFZ9FajIJ64M9N3oqtSiEvDZFggUS5fQ",
      authDomain: "briscoes-01082024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.SAKS) {
    authConfig = {
      apiKey: "AIzaSyBpiGVzBePTPmVoEGQCpLVyWt1ecI8yGFY",
      authDomain: "saksfifthavenue-27032024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.SAKSOFF) {
    authConfig = {
      apiKey: "AIzaSyBpiGVzBePTPmVoEGQCpLVyWt1ecI8yGFY",
      authDomain: "saksfifthavenue-27032024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.CRACKER_BARREL) {
    authConfig = {
      apiKey: "AIzaSyD3WLS3L98T5l6UgSCXB0fEGROGPPfmJ3s",
      authDomain: "crackerbarrel-16082024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.TOMMY_BAHAMA) {
    authConfig = {
      apiKey: "AIzaSyB3zl-pOFkK_9iQoDlwcxi0oXo4XUrJWUQ",
      authDomain: "tommy-bahama-393308.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.PETER_MILLAR) {
    authConfig = {
      apiKey: "AIzaSyCSha8vDV15n9A389KvJabXNzCx-mmHrEA",
      authDomain: "peter-millar-260624.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.STEVEMADDEN) {
    authConfig = {
      apiKey: "AIzaSyAEeRfvGHAg4bsxQWp4WxNVzx6urAQuXr0",
      authDomain: "steve-madden-280624.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.VS_INTERNATIONAL) {
    authConfig = {
      apiKey: "AIzaSyDkCvwpYOQA5qG-j0m4EHxKHE1E6YuS6Qc",
      authDomain: "victorias-secret-international.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.SPANX) {
    authConfig = {
      apiKey: "AIzaSyCc1kw3yaB_eYX6IW7SYbdZv-jfu-PsIiI",
      authDomain: "spanx-24042024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.JOANN) {
      authConfig = {
        apiKey: "AIzaSyCpGZU9k57UqxygEIC715wrDYGL4VYDt4k",
        authDomain: "joann-mtp-04112024.firebaseapp.com",
        whatFixUrl:
          "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
      };
  } else {
    authConfig = {
      apiKey: "AIzaSyDAZfJzJ57rMOyAgmLCgSc3L1NkpnKdFVY",
      authDomain: "impactsmart.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  }
} else {
  if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.VS) {
    authConfig = {
      apiKey: "AIzaSyAHKSk52_L_cMbAT0p31Cck6ed_7CsD8hw",
      authDomain: "victorias-secret-393308.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (
    [
      TENANT_MAPPING.SIGNET,
      TENANT_MAPPING.VB,
      TENANT_MAPPING.PARTY_CITY,
      TENANT_MAPPING.PUMA,
    ].includes(TENANT.toLocaleLowerCase())
  ) {
    authConfig = {
      apiKey: "AIzaSyCo4HZ-TWBP8U36B8d_klhy3ZPg0Jc5D3k",
      authDomain: "impactsmart-prod.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (
    [TENANT_MAPPING.PRODUCTIVITY_HELPER, TENANT_MAPPING.GENERIC, TENANT_MAPPING.MTP_INTERNAL].includes(
      TENANT.toLocaleLowerCase()
    )
  ) {
    authConfig = {
      apiKey: "AIzaSyCLzIDuxkqWZV2BvqUKSQU2MP9pdHkd06c",
      authDomain: "platform-internal.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.ARHAUS) {
    authConfig = {
      apiKey: "AIzaSyBcVTV1dvlmWyTeP1QlCMCw73Q_EuLnjy8",
      authDomain: "arhaus-401512.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.SAKSOFF) {
    authConfig = {
      apiKey: "AIzaSyBpiGVzBePTPmVoEGQCpLVyWt1ecI8yGFY",
      authDomain: "saksfifthavenue-27032024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.CRACKER_BARREL) {
    authConfig = {
      apiKey: "AIzaSyD3WLS3L98T5l6UgSCXB0fEGROGPPfmJ3s",
      authDomain: "crackerbarrel-16082024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.BRISCOES) {
    authConfig = {
      apiKey: "AIzaSyBKFZ9FajIJ64M9N3oqtSiEvDZFggUS5fQ",
      authDomain: "briscoes-01082024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.TOMMY_BAHAMA) {
    authConfig = {
      apiKey: "AIzaSyB3zl-pOFkK_9iQoDlwcxi0oXo4XUrJWUQ",
      authDomain: "tommy-bahama-393308.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.PETER_MILLAR) {
    authConfig = {
      apiKey: "AIzaSyCSha8vDV15n9A389KvJabXNzCx-mmHrEA",
      authDomain: "peter-millar-260624.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.STEVEMADDEN) {
    authConfig = {
      apiKey: "AIzaSyAEeRfvGHAg4bsxQWp4WxNVzx6urAQuXr0",
      authDomain: "steve-madden-280624.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.JOANN) {
    authConfig = {
      apiKey: "AIzaSyCpGZU9k57UqxygEIC715wrDYGL4VYDt4k",
      authDomain: "joann-mtp-04112024.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else {
    authConfig = {
      apiKey: "AIzaSyCo4HZ-TWBP8U36B8d_klhy3ZPg0Jc5D3k",
      authDomain: "impactsmart-prod.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  }
}

export default authConfig;
